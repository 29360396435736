<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="800"
    scrollable
  >
    <v-card :loading="isLoading">
      <v-card-title>
        <h5>{{ label || $_strings.contract.label.UPLOAD_CONTRACT }}</h5>
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="formAttachment"
        >
          <v-row>
            <v-col class="d-flex align-center pb-0" cols="12" sm="3">
              <p class="pb-2">
                {{ $_strings.contract.header.DOCUMENT_NAME }}<span class="red--text">*</span>
              </p>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                v-model="formAttachment.name"
                :rules="rules.attachmentNameRules"
                aria-hidden="true"
                dense
                height="30"
                outlined
                placeholder="Nama Dokumen"
                required>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" cols="3">
              <v-btn
                outlined
                plain
                @click="$refs.fileAttachment.$refs.input.click()"
              >
                Pilih File
              </v-btn>
            </v-col>
            <v-col class="pt-0">
              <v-text-field
                v-model="formAttachment.fileName"
                :rules="rules.attachmentFileNameRules"
                aria-hidden="true"
                dense
                disabled
                height="30"
                outlined
                placeholder="Nama File"
                required>
              </v-text-field>
            </v-col>
          </v-row>
          <upload-legend></upload-legend>
          <v-row justify="end">
            <v-btn
              color="primary"
              outlined
              @click="dialog = false"
            >
              {{ $_strings.contract.label.CANCEL }}
            </v-btn>
            <v-btn
              class="ml-4"
              color="primary"
              @click="submitAttachment"
            >
              {{ $_strings.contract.label.UPLOAD }}
            </v-btn>
          </v-row>
          <v-file-input
            ref="fileAttachment"
            class="d-none"
            v-model="fileUpload"
            @change="e => readFile(e)"
            type="file"
            accept=".pdf,.jpeg,.jpg,.png,.doc,.docx"
          >
          </v-file-input>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    label: {
      type: String,
      default: () => '',
    },
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        // RESET FORM UPLOAD
        this.$refs.formAttachment.reset();
        this.fileUpload = null;
        this.formAttachment = {
          name: '',
          fileName: '',
          fileType: '',
        };
        this.encodeFile = null;
      }
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      fileUpload: null,
      formAttachment: {
        name: '',
        fileName: '',
        fileType: '',
      },
      rules: {
        attachmentNameRules: [(v) => {
          if (!v || v.trim() === '') {
            return 'Nama dokumen wajib diisi';
          }
          return true;
        }],
        attachmentFileNameRules: [(v) => !!v || 'File wajib dipilih'],
      },
      encodeFile: null,
    };
  },
  methods: {
    dayjs,
    fileUploadValidation(fileType, size, fileSizeMin = 1024000) {
      // 1024000 => 1MB
      const fileTypeSupport = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'];
      if (!fileTypeSupport.includes(fileType)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return false;
      }
      if (size > fileSizeMin) {
        this.$dialog.notify.error(`Ukuran file tidak boleh lebih dari ${fileSizeMin / 1024000}MB.`);
        return false;
      }
      return true;
    },
    readFile(event) {
      if (!event) return;
      const { name, size } = event;
      const fileType = name.split('.').pop().toLowerCase();
      const fileSizeMin = 2048000;
      if (this.fileUploadValidation(fileType, size, fileSizeMin)) {
        this.formAttachment.fileName = name;
        this.formAttachment.fileType = fileType;
        const reader = new FileReader();
        reader.readAsDataURL(event);
        reader.onload = () => {
          this.encodeFile = reader.result;
        };
        reader.onerror = () => {
          this.$dialog.notify.error('Error memilih file');
          this.fileUpload = null;
        };
      } else {
        this.fileUpload = null;
      }
    },
    submitAttachment() {
      if (!this.$refs.formAttachment.validate()) {
        return;
      }
      this.$emit('uploadFile', this.formAttachment, this.encodeFile);
      // RESET FORM UPLOAD
      this.fileUpload = null;
      this.formAttachment = {
        name: '',
        fileName: '',
        fileType: '',
      };
    },
  },
};
</script>
